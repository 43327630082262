import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BREAK_POINTS } from '../../../../shared/constants/break-points.constant';

@Component({
    selector: 'app-header-university',
    imports: [TranslateModule],
    templateUrl: './header-university.component.html',
    styleUrl: './header-university.component.scss'
})
export class HeaderUniversityComponent implements OnInit {
  isTabletScreen!: boolean;
  isMobileScreen!: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private readonly platformID: Object
  ) {}
  ngOnInit(): void {
    this.initScreenType();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event & { target: { innerWidth: number } }) {
    this.addScreenTypeAndIsSideMenuOpen(event.target?.innerWidth);
  }

  initScreenType() {
    if (isPlatformBrowser(this.platformID)) {
      this.addScreenTypeAndIsSideMenuOpen(
        this.document.defaultView!.innerWidth
      );
    }
  }
  addScreenTypeAndIsSideMenuOpen(width: number) {
    if (width < BREAK_POINTS.mobile) {
      this.isMobileScreen = true;
      this.isTabletScreen = false;
    } else if (width < 1113) {
      this.isTabletScreen = true;
      this.isMobileScreen = false;
    } else {
      this.isTabletScreen = false;
      this.isMobileScreen = false;
    }
  }
}
