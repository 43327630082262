import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize } from 'rxjs';
import { ApiService } from '../../services/api/api.service';
import { INTERCEPTOR_SKIP_CONSTANT } from '../constants/interceptor-skip.constant';

export const requestCountInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const skipIntercept = req.headers.has(
    INTERCEPTOR_SKIP_CONSTANT.REQUEST_COUNT
  );
  if (skipIntercept) return next(req);

  const apiService = inject(ApiService);
  let requestCount: number = 0;
  if (requestCount === 0) {
    apiService.loading$.next(true);
  }
  requestCount++;
  return next(req).pipe(
    finalize(() => {
      requestCount--;
      if (requestCount === 0) {
        apiService.loading$.next(false);
      }
    })
  );
};
