import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, finalize } from 'rxjs';
import { ToastService } from '../../../core/services/toast/toast.service';
import { CardI } from '../../../shared/interfaces/card.interface';
import { DelegatedUIErrorI } from '../../../shared/interfaces/delegated-ui-error.interface';
import { FacultiesApiService } from '../faculties-api.service';
import { FacultyI } from '../interfaces/faculty.interface';
import { FacultiesDetailsSideMenuComponent } from './faculties-details-side-menu/faculties-details-side-menu.component';
import { FacultiesService } from '../faculties.service';

@Component({
  selector: 'app-faculties-details',
  imports: [FacultiesDetailsSideMenuComponent, TranslateModule, RouterOutlet],
  templateUrl: './faculties-details.component.html',
  styleUrl: './faculties-details.component.scss',
})
export class FacultiesDetailsComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly cd: ChangeDetectorRef,
    private readonly facultiesApiService: FacultiesApiService,
    private readonly facultiesService: FacultiesService
  ) {}
  facultiesCard: CardI[] = [
    {
      icon: 'course',
      number: 0,
      text: 'faculties.cards.totalCourses',
    },
    {
      icon: 'student',
      number: 0,
      text: 'faculties.cards.totalStudents',
    },
    {
      icon: 'instructor',
      number: 0,
      text: 'faculties.cards.totalInstructors',
    },
  ];
  private readonly destroy$ = new Subject<void>();
  facultyID: string | undefined = undefined;
  selectedFaculty: FacultyI | null = null;
  limit = 15;

  ngOnInit() {
    this.getFacultyIDFromRouteParams();
  }

  getFacultyIDFromRouteParams() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      const facultyID = paramMap.get('facultyID');
      if (!facultyID) {
        this.toastService.showError(
          this.translateService.instant('errors.contactTechnicalSupport')
        );
        this.location.back();
      } else {
        this.facultyID = facultyID;
        this.fetchFaculty();
      }
    });
  }

  fetchFaculty() {
    this.facultiesApiService
      .findOne(this.facultyID!)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.cd.detectChanges())
      )
      .subscribe({
        next: (findOneResponse) => {
          const { data } = findOneResponse;
          this.selectedFaculty = data;
          this.facultiesService.selectedFaculty.set(this.selectedFaculty);
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }
}
