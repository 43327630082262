import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../../core/services/toast/toast.service';
import { DelegatedUIErrorI } from '../../../shared/interfaces/delegated-ui-error.interface';
import { ActivitiesApiService } from '../activities-api.service';
import { ActivityI } from '../interfaces/activity.interface';
import { ActivitiesService } from '../activities.service';

@Component({
  selector: 'app-activity-details',
  imports: [TranslateModule, RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './activity-details.component.html',
  styleUrl: './activity-details.component.scss',
})
export class ActivityDetailsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  selectedActivity: ActivityI | null = null;
  isActivityReportUrl!: boolean;
  constructor(
    private readonly activitiesApiService: ActivitiesApiService,
    private readonly activitiesService: ActivitiesService,
    private readonly location: Location,
    private readonly toastService: ToastService,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {}
  ngOnInit(): void {
    this.getDataFromRoute();
  }

  getDataFromRoute() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      const activityID = paramMap.get('activityID');
      if (!activityID) {
        this.toastService.showError(
          this.translateService.instant('errors.contactTechnicalSupport')
        );
        this.location.back();
      } else {
        this.fetchActivity(activityID);
      }
    });
  }

  fetchActivity(activityID: string) {
    this.activitiesApiService
      .findOne(activityID)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (findOneResponse) => {
          const { data } = findOneResponse;
          this.selectedActivity = data;
          this.activitiesService.selectedActivity.set(data);
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
