import { DOCUMENT, NgClass } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Inject,
} from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { SessionService } from '../../../../core/services/session/session.service';
import { StorageService } from '../../../../core/services/storage/storage.service';
import { BREAK_POINTS } from '../../../../shared/constants/break-points.constant';
import { Role, ROLES } from '../../../../shared/enums/role.enum';
import { ScreenType } from '../../../../shared/enums/screen-type.enum';
import { NavItemI } from '../../../../shared/interfaces/nav-item.interface';
import { UserI } from '../../../../shared/interfaces/user.interface';
import { DROP_DOWN_ITEMS } from './constants/drop-down-items.constant';

@Component({
  selector: 'app-header-logged-in-user',
  imports: [TranslateModule, NgClass, RouterLink],
  templateUrl: './header-logged-in-user.component.html',
  styleUrl: './header-logged-in-user.component.scss',
})
export class HeaderLoggedInUserComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  isMobileScreen!: boolean;
  isTabletScreen!: boolean;
  private readonly destroy$ = new Subject<void>();
  loggedInUser?: UserI = undefined;
  roles = ROLES;
  isDropDownOpen = false;
  dropDownItems = DROP_DOWN_ITEMS;
  currentUrl: string = this.router.url.slice(1);
  role!: Role;

  constructor(
    private readonly sessionService: SessionService,
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.initScreenTypeListener();
    this.initLoggedInUser();
    this.listenToRouteEvents();
    this.role = Math.min(...this.sessionService.getDecodedToken().roles);
  }

  initLoggedInUser() {
    this.sessionService
      .getLoggedInUser$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (loggedInUser: UserI) => {
          this.loggedInUser = loggedInUser;
        },
      });
  }

  initScreenTypeListener() {
    if (this.document.defaultView!.innerWidth < BREAK_POINTS.mobile) {
      this.isMobileScreen = true;
      this.isTabletScreen = false;
    } else if (
      this.document.defaultView!.innerWidth <
      BREAK_POINTS.tablet + 9
    ) {
      this.isMobileScreen = false;
      this.isTabletScreen = true;
    }
    this.sessionService
      .getScreenType$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (screenType) => {
          this.isMobileScreen = screenType === ScreenType.MOBILE;
          this.isTabletScreen = screenType === ScreenType.TABLET;
        },
      });
  }

  public getDropDownItemIcon(dropDownItem: NavItemI) {
    return dropDownItem.route[0] === this.currentUrl! ||
      (this.currentUrl === undefined && dropDownItem.alt === 'Home')
      ? dropDownItem.activeIcon
      : dropDownItem.nonActiveIcon;
  }

  listenToRouteEvents(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url.slice(1);
      }
    });
  }

  public isCurrentRouteActive(dropDownItem: NavItemI) {
    return dropDownItem.route[0] === this.currentUrl!;
  }

  openCloseDropDown() {
    this.isDropDownOpen = !this.isDropDownOpen;
  }

  isRtl() {
    return this.storageService.language === 'ar';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  extractInitials(name: string) {
    name = (name ?? 'guest user').trim().toUpperCase();
    return name[0] + ' ' + name[name.lastIndexOf(' ') + 1];
  }
}
